.required:after {
    content: "*";
    color: red;
}

.card-header--list {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/*Select2 ReadOnly Start*/
select[readonly] {
    background: #eee; /*Simular campo inativo - Sugestão @GabrielRodrigues*/
    pointer-events: none;
    touch-action: none;
}

select[readonly].select2-hidden-accessible + .select2-container {
    pointer-events: none;
    touch-action: none;
}

select[readonly].select2-hidden-accessible
    + .select2-container
    .select2-selection {
    background: #eee;
    box-shadow: none;
}

select[readonly].select2-hidden-accessible
    + .select2-container
    .select2-selection__arrow,
select[readonly].select2-hidden-accessible
    + .select2-container
    .select2-selection__clear {
    display: none;
}

/*Select2 ReadOnly End*/
